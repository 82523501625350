 <template>
    <section class="blockElement vuewFullSocialFeed pb-3 pt-md-5 mt-3" :class="[{'withoutExpand':!store.user.access_token}]">
        <div class="container-fluid">
            <loginPopup v-if="showLoginPopup"></loginPopup>
            <div class="row">
                <div class="col-12 col-xl-3">
                    <h2 class="f-28 bold d-flex align-items-center mb-4"><img class="me-2" src="/assets/images/small-feed.png" :alt="$t('leader_profile.feed')" /> {{$t('leader_profile.feed')}}</h2>
                    <div class="verticalTabs calcLeftHeight">
                        <ul>
                            <li>
                                <router-link :to="{name: 'socialFeed'}" class="d-flex p-3 rounded align-items-center active">
                                    <span class="me-3 d-flex"> <span class="maskedIcon"></span> </span>
                                    <span class="mb-0 f-18 medium">{{$t('leader_profile.socialFeed')}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-xl-6">
                    <div class="mb-3" v-if="(commentInfo.content || []).length > 0">
                        <div class="bg-white boxed radius12 card-body mb-2">
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <div class="d-flex align-items-center">
                                    <span class="me-2">
                                        <router-link :to="'/trader/'+commentInfo.providerId+'/trading?t=10000&m=1'">
                                            <v-lazy-image v-if="commentInfo.fromZuluAccountId" width="40" height="40" class="rounded-circle d-block" :src="static_vars.imagesURL+'/webservices/Image.ashx?type=user&size=XL&id='+commentInfo.fromZuluAccountId+'&ignore=true'" :alt="commentInfo.publisher" :title="commentInfo.publisher" />
                                            <v-lazy-image v-else width="40" height="40" class="rounded-circle d-block" src="/assets/images/profile-pic.png" :alt="$t('copy_strategy.text1')" :title="$t('copy_strategy.text1')" />
                                        </router-link>
                                    </span>
                                    <div class="flex-shrink-0">
                                        <router-link :to="'/trader/'+commentInfo.providerId+'/trading?t=10000&m=1'" v-if="commentInfo.providerId"><h6 class="medium f-15 mb-0">{{commentInfo.publisher || '-'}}</h6></router-link>
                                        <!-- && commentInfo.providerId != store.traderDetail.trader.stats.providerId -->
                                        <h6 v-else class="medium f-15 mb-0">{{commentInfo.publisher || '-'}}</h6>
                                        <p class="mb-0 line-1 gray small">{{dateFormat(commentInfo.time)}}</p>
                                    </div>
                                </div>   
                                <div class="position-relative dropdown" :class="{'disabled disable-bg' : store.customerDetail?.readOnly}" v-if="store.user.access_token && commentInfo.actions && commentInfo.actions.length">
                                    <button class="morebtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="dropdownMenuButton1">
                                        <li v-for="list,key in commentInfo.actions" :key="key"><a class="dropdown-item f-14 captilize" href="javascript:void(0)" @click="typeDelete(commentInfo,'socialDetail')" v-if="list.type =='TRANSLATE'">
                                            {{(list.type=='TRANSLATE' && istrans.includes(commentInfo.socialEventId))? $t('leader_profile.original') :list.type.toLowerCase()}}
                                        </a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="customPad viewNews">
                                <div class="py-1">
                                    <video style="height:300px" class="controlsVideo br-10 my-3" controls :src="commentInfo.attachmentUrl" v-if="commentInfo.attachmentUrl && commentInfo.attachmentUrl.indexOf('mp4') !== -1"></video>
                                    <v-lazy-image class="my-3 br-10" :src="commentInfo.attachmentUrl" alt="image" @click="lightboxURL = social.attachmentUrl" style="height:300px;object-fit:cover" v-else-if="commentInfo.attachmentUrl && commentInfo.attachmentUrl.indexOf('mp4') == -1" />
                                    <p v-if="commentInfo.translated && istrans.includes(commentInfo.socialEventId)" class="mb-1 f-15 neutralGrey" v-html="findHastag(commentInfo.translated,commentInfo.providerId)"></p>
                                    <p v-else class="mb-1 f-15 neutralGrey" v-html="findHastag(commentInfo.content,commentInfo.providerId)"></p>

                                </div>
                                <div v-if="commentInfo.link">
                                    <a :href="commentInfo.link.url" target="_blank">
                                        <div class="d-flex border align-items-center mt-2 bg-light rounded overflow-hidden">
                                            <v-lazy-image class="bg-white p-3 border-right" :alt="commentInfo.link.imageAlt" :src="commentInfo.link.image" :title="commentInfo.link.title" height="150" width="150" />
                                            <div class="ps-4">
                                                <p class="f-12 mb-0">{{commentInfo.link.domain}}</p>
                                                <p class="f-14 medium f-15 mb-0">{{commentInfo.link.title}}</p>
                                                <p class="f-12 mb-0">{{commentInfo.link.desc}}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <ul class="commentArea d-flex align-items-center mt-2">
                                    <li>
                                        <a class="d-flex align-items-center" :class="{'disabled disable-bg' : store.customerDetail?.readOnly}" href="javascript:void(0)"><vue-feather type="heart" :fill="commentInfo.liked==true?'#ff7200':'none'" :stroke="commentInfo.liked==true?'#ff7200':'#778290'" @click="liked(commentInfo.liked,commentInfo.socialEventId,'socialDetail')" class=" w-16 me-1"></vue-feather > {{commentInfo.likes}}</a>
                                    </li>
                                    <li>
                                        <a class="d-flex align-items-center" :class="{'disabled disable-bg' : store.customerDetail?.readOnly}" href="javascript:void(0)"><vue-feather type="message-square" class=" w-16 me-1"></vue-feather > {{commentInfo.totalComments}}</a>
                                    </li>
                                    <li>
                                        <a class="d-flex align-items-center" href="javascript:void(0)" @click="sharePop=commentInfo.eventId"><vue-feather  type="share-2" class="me-1 w-16 "></vue-feather ></a>
                                    </li>
                                </ul>
                                <!-- Social child comments -->
                                <div class="ntChild" v-if="commentInfo.comments && commentInfo.comments.length">
                                    <div class="card-body border-0 mb-1" v-for="item,key in commentInfo.comments" :key="key">
                                        <div class="d-flex align-items-center justify-content-between mb-2">
                                            <div class="d-flex align-items-center">
                                                <span class="me-2">
                                                    <v-lazy-image v-if="item.fromZuluAccountId" width="40" height="40" class="rounded-circle d-block" :src="static_vars.imagesURL+'/webservices/Image.ashx?type=user&size=XL&id='+item.fromZuluAccountId+'&ignore=true'" :alt="item.publisher" :title="item.publisher" />
                                                    <v-lazy-image v-else width="40" height="40" class="rounded-circle d-block" src="/assets/images/profile-pic.png" :alt="$t('copy_strategy.text1')" :title="$t('copy_strategy.text1')" />
                                                </span>
                                                <div class="flex-shrink-0">
                                                    <h6 class="medium f-15 mb-0">{{item.publisher || '-'}}</h6>
                                                    <p class="mb-0 line-1 gray small">{{dateFormat(item.dateCreated)}}</p>
                                                </div>
                                            </div>      
                                            <div class="position-relative dropdown" :class="{'disabled disable-bg' : store.customerDetail?.readOnly}" v-if="store.user.access_token && item.actions && item.actions.length">
                                                <button class="morebtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="dropdownMenuButton1">
                                                    <li v-for="childList,key in item.actions" :key="key"><a v-if="childList.type =='TRANSLATE'" class="dropdown-item f-14 captilize" href="javascript:void(0)" @click="typeDelete(item,'socialChildDetail')">
                                                        {{(childList.type=='TRANSLATE' && istrans.includes(item.socialEventId))? $t('leader_profile.original') :childList.type.toLowerCase()}}</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="customPad viewNews">
                                            <div>
                                                <p v-if="item.translated && istrans.includes(item.socialEventId)" class="mb-1 f-15 neutralGrey" v-html="findHastag(item.translated,item.providerId)"></p>
                                                <p v-else class="mb-1 f-15 neutralGrey" v-html="findHastag(item.content,item.providerId)"></p>
                                            </div>
    
                                            <ul class="commentArea d-flex align-items-center mt-2">
                                                <li>
                                                    <a class="d-flex align-items-center" :class="{'disabled disable-bg' : store.customerDetail?.readOnly}" href="javascript:void(0)"><vue-feather  type="heart" :fill="item.liked==true?'#ff7200':'none'" :stroke="item.liked==true?'#ff7200':'#778290'" @click="liked(item.liked,item.socialEventId,'socialChildDetail')" class=" w-16 me-1"></vue-feather >{{item.likes}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Nodata v-else></Nodata>
                </div>
                <div class="col-12 col-xl-3">
                    <div class="position-sticky rightBarFixed">
                        <!-- <div class="bg-white boxed px-md-5 py-md-4 mb-4">
                            <div class="inputForm">
                                <h3 class="text-center mb-md-5 mb-4">Copytrade with Zulutrade</h3>
                                <a href="javascript:void(0)" class="borderButton d-flex align-items-center mb-2">
                                    <v-lazy-image src="/assets/images/google-login.svg" loading="lazy" width="24" height="24" alt="google" title="Google Login" /><span>Continue with Google</span>
                                </a>
                                <a href="javascript:void(0)" class="borderButton d-flex align-items-center mb-2">
                                    <v-lazy-image src="/assets/images/mac-login.svg" loading="lazy" width="24" height="24" alt="Apple" title="Continue with Apple" /><span>Continue with Apple</span>
                                </a>
                            </div>
                        </div> -->
                        <Sidebar></Sidebar>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Share Popup -->
    <sharePop :shareURL="sharePop" :close="()=>sharePop = ''" v-if="sharePop" :shareType="'socialFeed'"></sharePop>
    <!-- Lightbox -->
    <div class="modal show fade" style="display: block;" v-if="lightboxURL">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <button type="button" class="btn-close" @click="lightboxURL=''"></button>
                </div>
                <div class="modal-body p-0 border-bottom text-center">
                    <v-lazy-image class="d-block mx-auto" :src="lightboxURL" :alt="$t('leader_profile.lightbox')"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import Sidebar from '@/views/social-feed/sidebar.vue'
    import sharePop from "@/views/social-feed/share-pop"
    import moment from "moment";
    import $ from "jquery"
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        components: {
            Sidebar,sharePop
        },
        data(){
            return {
                commentInfo: [],
                istrans: [],
                likeUnlike: '',
                showLoginPopup: false,
                sharePop: '',
                lightboxURL: '',
            }
        },
        methods: {
            onCopy(e){
                alert('You just copied: ' + e.text)
            },
            onError(){
                alert('Failed to copy texts')
            },
            typeDelete(social,listType){
                if(listType=='socialDetail'){
                    if(this.store.socialCommentList.socialEventId == social.socialEventId && this.store.socialCommentList.translated){
                        let myIndex = this.istrans.indexOf(social.socialEventId)
                        if(myIndex == -1){
                            this.istrans.push(social.socialEventId)
                        }else{
                            this.istrans.splice(myIndex, 1)
                        }
                    } else{
                        this.store.socialTranslate({"text": social.content,"to": "en"},false,social.socialEventId,listType).then(()=>{
                            this.istrans.push(social.socialEventId)
                        })
                    }
                } else if(listType=='socialChildDetail'){
                    const find = this.store.socialCommentList.comments.find(v=>v.socialEventId == social.socialEventId)
                    if(find && find.translated){
                        let myIndex = this.istrans.indexOf(social.socialEventId)
                        if(myIndex == -1){
                            this.istrans.push(social.socialEventId)
                        }else{
                            this.istrans.splice(myIndex, 1)
                        }
                    } else{
                        this.store.socialTranslate({"text": social.content,"to": "en"},false,social.socialEventId,listType).then(()=>{
                            this.istrans.push(social.socialEventId)
                        })
                    }
                }
            },
            findHastag(text,prID){
                let hastag = text.replace(/#(\w+)/g, '<a href="javascript:void(0)" class="menutext medium hashlink">#$1</a>');
                let hasAt = hastag.replace(/@(\w+)/g, '<a href="javascript:void(0)" id="'+prID+'" class="menutext medium traderlink">@$1</a>');
                let thirdLink = hasAt.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" class="menutext medium thirdlink">$1</a>');
                let br = thirdLink.replace(/\\r\\n/g, "<br />")
                return br.trim()
            },
            dateFormat(date){
                let format = moment(date).toString()
                return moment(format).fromNow()
            },
            liked(elem,socialEventId,type){
            if(this.store.user.access_token){
                if(elem){
                    this.likeUnlike = 'unlike'
                    this.store.callSocialLikeUnlike({},false,socialEventId,this.likeUnlike,type)
                } else {
                    this.likeUnlike = 'like'
                    this.store.callSocialLikeUnlike({},false,socialEventId,this.likeUnlike,type)
                }
            } else {
                this.showLoginPopup = true
            }
        },
        },
        created() {
            if(this.$route.query.eventId){
                this.store.callSocialCommentList({},false,this.$route.query.eventId).then(()=>{
                    this.commentInfo = this.store.socialCommentList
                })
            }
        },
        updated() {
            var _that = this
            $(".traderlink").click(function(e){
                let weblink = "/trader/" + e.target.id + "/trading?t=10000&m=1";
                _that.$router.replace(weblink)
                return false;
            });
        },
   
    };
</script>